* {
	box-sizing: border-box;
}
body {
	background: gray;
	font-family: open_sansregular, serif;
	font-size: 16px;
}
select {
	-webkit-appearance: none;
	-moz-appearance: none;
}
.App {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0px 0px 0px 0px;
	text-align: left;
	background: white;
}
.bold {
	font-family: open_sansbold, serif;
}
/* ======== HEADER ======== */
.header-img {
	margin-bottom: 50px;
}
.headerContainer {
	display: flex;
	min-height: 360px;
	background: #eaeaea;
}
.tsHeader {
	width: 40%;
	max-height: 333px;
	margin-left: 5%;
	padding: 55px 2% 50px 4%;
	color: white;
	line-height: 1.2;
	background: #2d2d2d;
	box-shadow: 4px 4px 12px 0px #949494;
}
.headerData {
	line-height: 1.3;
}
.tsClientInfo {
	padding: 20px 20px 70px 70px;
}
.headerNumber {
	margin-top: 40px;
	font-size: 22px;
	font-family: open_sanssemibold, serif;
	color: #4d9feb;
}
.headerSectionLabel {
	margin: 28px 0 7px 0;
	color: gray;
	font-size: 12px;
}

/* ======== FORM ======== */
.form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 40px 1fr;
}
.formColumn {
	padding: 10px 0 20px 0;
}
.sectionHeader {
	grid-column-start: 1;
	grid-column-end: 3;
	color: white;
	background: #353535;
}
.formHeader {
	padding: 4px 10% 16px 10%;
	color: white;
	line-height: 2.0;
	background: #353535;
	box-shadow: 0px 4px 10px 0px rgba(53, 53, 53, 0.4);
}
.label, .input {
	width: 80%;
    display: block;
    text-align: left;
}
.input {
	margin: 1px 10% 16px 10%;
	padding: 5px 15px;
	font-size: 14px;
	color: #454545;
	border: solid 1px darkgray;
	border-radius: 5px;
	background: none;
}
.label {
	position: relative;
	font-size: 12px;
	color: #797979;
	margin: 4px 10% 0px 10%;
}
/* ======== ITEMS ======== */
.itemsList {
	padding-bottom: 20px;
}
.itemsListHeader {
	height: 40px;
	margin-bottom: 13px;
	display: flex;
	align-items: center;
	padding: 0px 4.5%;
	color: white;
	line-height: 2.0;
	background: #353535;
	box-shadow: 0px 4px 10px 0px rgba(53, 53, 53, 0.4);
}
.itemRow {
	position: relative;
	display: flex;
	padding: 6px 4.5%;
}
.itemRow:after {
	position: absolute;
	bottom: 0;
	content: '';
	width: 90%;
	height: 1px;
	background: gray;
}
.item {
	margin-bottom: 5px;
}
.radio {
	cursor: pointer;
}
.radio, .radioLabel {
	display: inline-block;
	margin-right: 8px;
	vertical-align: middle;
}
.finalRow {
	display: flex;
	padding: 23px 4.5% 10px 4.5%;
}
.total {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
}
.total:after {
	content: 'TOTAL';
	background: #e2e2e2;
	width: 125%;
	text-align: left;
	display: block;
	padding-right: 100px;
	position: absolute;
	padding: 9px 14% 9px 22px;
	right: 10px;
	border-radius: 5px;
	font-family: open_sansbold;
	z-index: -1;
}

/* ====== FOOTER ====== */
.footer {
	background: #eaeaea;
	padding: 0px 5% 50px 5%;
	box-shadow: inset 0px 4px 11px #d0d0d0;
}
.row {
	display: flex;
	align-items: flex-end;
}
.footer label {
	margin-left: 20%;
	color: black;
}
.footer input {
	margin-left: 20%;
	margin-bottom: 0;
	background: white;
}
.footer i {
	margin-right: 8px;
}
button.acceptBtn {
	margin-left: 15px;
	padding: 6px 23px;
	font-size: 14px;
	color: white;
	border: none;
	border-radius: 5px;
	background: green;
	cursor: pointer;
}
button.acceptBtn:hover {
	background: #005d00;
}
button.acceptBtn:focus {
	outline: none;
}
.statusBoxContainer {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 110px;
	text-align: center;
	overflow: hidden;
}
.statusBox {
	position: relative;
	top: 0;
	padding: 20px 0;
	font-size: 20px;
	transition: top .4s;
}
.statusBox i {
	color: green;
}
.approvedMessage {
	font-family: open_sanssemibold;
	font-size: 30px;
	color: green;
}
#msg2 {
	position: absolute;
	top: 110px;
}
.slideUp1 {
	top: -100px;
}
#msg2.slideUp2 {
	top: 0px;
}
.errorMsg {
	color: red;
	position: absolute;
	top: -28px;
	left: 0;
	margin-top: 0px;
	font-size: 20px;
	font-family: open_sansitalic;
}


/* ======= COMMON ======= */
.specialMessage {
	padding: 30px 10% 0 10%;
	color: #4d9feb;
}
i {
	margin-right: 5px;
	vertical-align: inherit;
}
.fg-1 {flex-grow: 1}
.fg-2 {flex-grow: 2}
.fg-3 {flex-grow: 3}

.w-10 {width: 10%}
.w-15 {width: 15%}
.w-20 {width: 20%}
.w-30 {width: 30%}
.w-60 {width: 60%}
.w-65 {width: 65%}
.w-70 {width: 70%}

.ta-c {text-align: center}
.ta-r {text-align: right}

.hidden {
	display: none;
}
.warn {color: red;}

@font-face {
    font-family: 'open_sansbold';
    src: url('./fonts/OpenSans-Bold-webfont.eot');
    src: url('./fonts/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/OpenSans-Bold-webfont.woff') format('woff'),
         url('./fonts/OpenSans-Bold-webfont.ttf') format('truetype'),
         url('./fonts/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansbold_italic';
    src: url('./fonts/OpenSans-BoldItalic-webfont.eot');
    src: url('./fonts/OpenSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/OpenSans-BoldItalic-webfont.woff') format('woff'),
         url('./fonts/OpenSans-BoldItalic-webfont.ttf') format('truetype'),
         url('./fonts/OpenSans-BoldItalic-webfont.svg#open_sansbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'open_sansextrabold';
    src: url('./fonts/OpenSans-ExtraBold-webfont.eot');
    src: url('./fonts/OpenSans-ExtraBold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/OpenSans-ExtraBold-webfont.woff') format('woff'),
         url('./fonts/OpenSans-ExtraBold-webfont.ttf') format('truetype'),
         url('./fonts/OpenSans-ExtraBold-webfont.svg#open_sansextrabold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'open_sansextrabold_italic';
    src: url('./fonts/OpenSans-ExtraBoldItalic-webfont.eot');
    src: url('./fonts/OpenSans-ExtraBoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/OpenSans-ExtraBoldItalic-webfont.woff') format('woff'),
         url('./fonts/OpenSans-ExtraBoldItalic-webfont.ttf') format('truetype'),
         url('./fonts/OpenSans-ExtraBoldItalic-webfont.svg#open_sansextrabold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'open_sansitalic';
    src: url('./fonts/OpenSans-Italic-webfont.eot');
    src: url('./fonts/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/OpenSans-Italic-webfont.woff') format('woff'),
         url('./fonts/OpenSans-Italic-webfont.ttf') format('truetype'),
         url('./fonts/OpenSans-Italic-webfont.svg#open_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'open_sanslight';
    src: url('./fonts/OpenSans-Light-webfont.eot');
    src: url('./fonts/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/OpenSans-Light-webfont.woff') format('woff'),
         url('./fonts/OpenSans-Light-webfont.ttf') format('truetype'),
         url('./fonts/OpenSans-Light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'open_sanslight_italic';
    src: url('./fonts/OpenSans-LightItalic-webfont.eot');
    src: url('./fonts/OpenSans-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/OpenSans-LightItalic-webfont.woff') format('woff'),
         url('./fonts/OpenSans-LightItalic-webfont.ttf') format('truetype'),
         url('./fonts/OpenSans-LightItalic-webfont.svg#open_sanslight_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'open_sansregular';
    src: url('./fonts/OpenSans-Regular-webfont.eot');
    src: url('./fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/OpenSans-Regular-webfont.woff') format('woff'),
         url('./fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('./fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'open_sanssemibold';
    src: url('./fonts/OpenSans-Semibold-webfont.eot');
    src: url('./fonts/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/OpenSans-Semibold-webfont.woff') format('woff'),
         url('./fonts/OpenSans-Semibold-webfont.ttf') format('truetype'),
         url('./fonts/OpenSans-Semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'open_sanssemibold_italic';
    src: url('./fonts/OpenSans-SemiboldItalic-webfont.eot');
    src: url('./fonts/OpenSans-SemiboldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/OpenSans-SemiboldItalic-webfont.woff') format('woff'),
         url('./fonts/OpenSans-SemiboldItalic-webfont.ttf') format('truetype'),
         url('./fonts/OpenSans-SemiboldItalic-webfont.svg#open_sanssemibold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sansCnLtIt';
    src: url('./fonts/OpenSans-CondLightItalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sanscondensed_light';
    src: url('./fonts/OpenSans-CondLight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sans_condensedbold';
    src: url('./fonts/OpenSans-CondBold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@media print {
	body {
		color: black !important;
	}
	.tsHeader {
		color: black;
		box-shadow: none;
		border-right: solid 1px black;
	}
	.formHeader, .itemsListHeader {
		color: black;
		box-shadow: none;
		border-bottom: solid 1px black;
	}
	.itemRow:after {
		border-bottom: solid 1px black;
	}
	img.header-img {
		filter: brightness(0);
	}
	.footer {
		box-shadow: none;
		border-top: solid 1px black;
	}
}




























